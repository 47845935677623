<template>
    <div class="p-4 bg-white shadow rounded-sm">
        <div class="row">
            <div class="col-12 col-lg-4 col-xl-3">
                <b-form-group>
                    <b-form-radio-group
                        v-model="showDataTable"
                        :options="dataTableOptions"
                    />
                </b-form-group>
            </div>
            <div class="col-12 col-lg-6">
                <b-form-group>
                    <b-form-checkbox
                        v-model="filterPoor"
                        switch
                    >
                        zobrazit jen klienty s majetkem >= 5000
                    </b-form-checkbox>
                </b-form-group>
            </div>
        </div>
        <data-table
            class="table-sm-font"
            :auto-update="false"
            :header="header"
            :loading="loading"
            :data="filteredDataset"
            :buttons="buttons"
            :lang="currentLang"
            :paging="true"
            @positions="onPositions"
            @lastOrders="onLastOrders"
            @agentPositions="onAgentPositions"
            @agentLastOrders="onAgentLastOrders"
            :responsive="'lg'"
            :exportable="true"
            @export="onExport"
        />
    </div>
</template>

<script>
import XLSX from 'xlsx'
import download from 'downloadjs'

const numberFormatter = new Intl.NumberFormat('cs-CZ')
const formatCurrency = (value) => {
    if (typeof value === 'number') {
        return numberFormatter.format(Math.round(value * 100) / 100)
    }
    return 0
}

export default {
    name: 'PropertyDataTable',
    props: {
        dataset: {
            type: Array,
            required: false,
            default: () => []
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            filterPoor: false,
            showDataTable: 'clients'
        }
    },
    computed: {
        dataTableOptions () {
            return [
                {
                    value: 'clients',
                    text: this.$t('propertyDataTable.clientOption')
                },
                {
                    value: 'agents',
                    text: this.$t('propertyDataTable.agentOption')
                }
            ]
        },
        filteredDataset () {
            let data = []
            if (this.filterPoor) {
                data = this.dataset.filter(item => item.volumeOfProperty && item.volumeOfProperty >= 5000)
            } else {
                data = this.dataset
            }
            const foundAgents = []
            if (this.showDataTable === 'agents') {
                data = [...new Set(data.map(
                    item => {
                        return JSON.stringify({
                            brokerId: item.brokerId,
                            externalBrokerId: item.externalBrokerId,
                            externalBrokerAgentId: item.externalBrokerAgentId,
                            externalBrokerAgentName: item.externalBrokerAgentName,
                            externalBrokerAgentReferenceCode: item.externalBrokerAgentReferenceCode
                        })
                    }
                ))].map(JSON.parse).map(item => {
                    const tmp = data.filter(item => !foundAgents.includes(item.externalBrokerAgentId))
                    const dataset = {
                        ...item,
                        volumeOfProperty: tmp.reduce((sum, entry) => sum + ((entry.volumeOfProperty && item.externalBrokerAgentId === entry.externalBrokerAgentId) ? parseInt(entry.volumeOfProperty) : 0), 0),
                        numberOfClients: tmp.reduce((sum, entry) => sum + ((item.externalBrokerAgentId === entry.externalBrokerAgentId) ? 1 : 0), 0)
                    }
                    foundAgents.push(item.externalBrokerAgentId)
                    return dataset
                })
                data.sort((a, b) => (a.volumeOfProperty === b.volumeOfProperty ? 0 : ((a.volumeOfProperty > b.volumeOfProperty) ? -1 : 1)))
                return data
            }
            if (this.filterPoor) {
                return data
            }
            return this.dataset
        },
        currentLang () {
            return (this.$store.state.lang === 'cz') ? 'cs_CZ' : 'en_US'
        },
        header () {
            return [
                ...((this.showDataTable === 'clients') ? [{
                    text: 'Capitol ID',
                    data: 'capitolId',
                    sortable: true,
                    filterable: true,
                    format: (value) => {
                        return value || this.$t('propertyDataTable.noCapitolId')
                    }
                },
                {
                    text: 'Jméno klienta',
                    data: 'name',
                    sortable: true,
                    filterable: true
                }] : []),
                {
                    text: 'Agent',
                    data: 'externalBrokerAgentName',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Ref. kód',
                    data: 'externalBrokerAgentReferenceCode',
                    sortable: true,
                    filterable: true
                },
                ...((this.showDataTable !== 'clients') ? [{
                    text: 'Počet klientů',
                    data: 'numberOfClients',
                    sortable: true,
                    filterable: true
                }] : []),
                {
                    text: 'Objem majetku v CZK',
                    data: 'volumeOfProperty',
                    sortable: true,
                    filterable: true,
                    format: formatCurrency,
                    sortFn (a, b) {
                        return ((a === b) ? 0 : ((a > b) ? 1 : -1))
                    },
                    aggregate (sum, item, index, rows) {
                        sum += ((item.volumeOfProperty) ? parseInt(item.volumeOfProperty) : 0)
                        if (index === rows.length - 1) {
                            return formatCurrency(sum)
                        }
                        return sum
                    },
                    aggregateInitialValue: 0,
                    aggregateText: this.$t('propertyDataTable.total')
                }
            ]
        },
        buttons () {
            return [
                {
                    event: 'positions',
                    variant: 'primary',
                    text: this.$t('propertyDataTable.positions'),
                    visibleIf: () => {
                        return this.showDataTable === 'clients'
                    }
                },
                {
                    event: 'lastOrders',
                    variant: 'primary',
                    text: this.$t('propertyDataTable.lastOrders'),
                    visibleIf: () => {
                        return this.showDataTable === 'clients'
                    }
                },
                {
                    event: 'agentPositions',
                    variant: 'primary',
                    text: this.$t('propertyDataTable.positions'),
                    visibleIf: () => {
                        return this.showDataTable === 'agents'
                    }
                },
                {
                    event: 'agentLastOrders',
                    variant: 'primary',
                    text: this.$t('propertyDataTable.lastOrders'),
                    visibleIf: () => {
                        return this.showDataTable === 'agents'
                    }
                }
            ]
        }
    },
    methods: {
        onPositions (row) {
            this.$emit('positions', row)
        },
        onLastOrders (row) {
            this.$emit('lastOrders', row)
        },
        onAgentPositions (row) {
            this.$emit('agentPositions', row)
        },
        onAgentLastOrders (row) {
            this.$emit('agentLastOrders', row)
        },
        onExport (data) {
            const wk = XLSX.utils.book_new()
            const [jsonHeader, ...rows] = data
            const formatAsNumber = (value) => {
                // eslint-disable-next-line no-useless-escape
                return Number(`${value}`.replace(/[^0-9,\.]/g, '').replace(',', '.') ?? 0)
            }
            const sheet = XLSX.utils.json_to_sheet([jsonHeader, ...rows.map(row => {
                if (row.numberOfClients !== undefined) {
                    row.numberOfClients = formatAsNumber(row.numberOfClients)
                }
                if (row.volumeOfProperty !== undefined) {
                    row.volumeOfProperty = formatAsNumber(row.volumeOfProperty)
                }
                return row
            })], { skipHeader: true })
            XLSX.utils.book_append_sheet(wk, sheet, 'export')
            const output = XLSX.write(wk, { bookType: 'xlsx', bookSST: false, type: 'array' })
            download(
                new Blob([output], { type: 'application/octet-stream' }),
                'majetek.xlsx',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            )
        }
    }
}
</script>

<i18n>
{
    "cz": {
        "propertyDataTable": {
            "positions": "Pozice",
            "noCapitolId": "Neevidováno",
            "total": "Celkem: ",
            "lastOrders": "Pokyny",
            "clientOption": "Majetek dle klientů",
            "agentOption": "Majetek dle agentů"
        }
    },
    "en": {
        "propertyDataTable": {
            "positions": "Positions",
            "noCapitolId": "Not in evidence",
            "total": "Total: ",
            "lastOrders": "Orders"
        }
    }
}
</i18n>
